export function useMeta() {
    const setTitle = (value?: string) => {
        if (value) {
            document.title = value
        }
    }

    const setMetaTag = (name: string, value: string) => {
        if (value) {
            const meta = document.querySelector(`meta[name="${name}"]`) || document.createElement('meta')

            if (meta.getAttribute('name') === name) {
                meta.setAttribute('name', name)
            }

            meta.textContent = value
        }
    }

    const setDescription = (value: string) => {
        setMetaTag('description', value)
    }

    return {
        setTitle,
        setMetaTag,
        setDescription
    }
}