<template>
  <div class="tw-h-screen tw-w-screen">
    <router-view />
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
