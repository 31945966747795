import { defineComponent as __define__ } from 'vue'
import { Slots as __Slots__ } from 'vue'
import { computed, ref } from "vue";


export function setup() {

const examples = [
  {
    title: "DFØ Employees",
    route: "DFOEmployees",
    description:
      "Sunburst graph displaying all DFØ public employees across the different sections."
  },
  {
    title: "OECD Employees",
    route: "OECDEmployees",
    description:
      "Sunburst graph displaying all OECD employees across the different departments and sections of the organization. The data is pulled from the OECD phone directory."
  },
  {
    title: "Norwegian Employees",
    route: "NorwegianEmployees",
    description:
      "Sunburst graph displaying all Norwegian public employees across the different departments and sections of the ministries. The data is pulled from the Depkatalog API (https://depkatalog.no)."
  }
];

/**
 * Current respondent
 */
const respondent = ref("FR");

/**
 * Comparisons.
 */
const comparisons = ref(["IT"]);

/**
 * EUPAN link.
 */
const eupanLink = computed(() => {
  return `https://beta.apenhet.com/eupan?=respondent=${
    respondent.value
  }&comparisons=${comparisons.value.join(",")}`;
});

/**
 *
 */
const getPreview = (name: string) => {
  try {
    return require(`@/assets/previews/${name}.png`);
  } catch (error) {
    return null;
  }
};

return { examples, respondent, comparisons, eupanLink, getPreview }
}

export default __define__({
  setup
})