import { useMeta } from '@/composables/meta'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      description: "Sunburst graph displaying all DFØ public employees across the different sections.",
      title: "Åpenhet - BETA"
    },
  },
  {
    path: '/dfo-employees',
    name: 'DFOEmployees',
    meta: {
      description: "Sunburst graph displaying all DFØ public employees across the different sections.",
      title: "DFØ Employees"
    },
    component: () => import('@/views/DFOEmployees.vue')
  },
  {
    path: '/norwegian-employees',
    name: 'NorwegianEmployees',
    meta: {
      description: "Sunburst graph displaying all Norwegian public employees across the different departments and sections of the ministries. The data is pulled from the Depkatalog API (https://depkatalog.no).",
      title: 'Norwegian Employees'
    },
    component: () => import('@/views/NorwegianEmployees.vue')
  },
  {
    path: '/oecd-employees',
    name: 'OECDEmployees',
    meta: {
      description: "Sunburst graph displaying all Norwegian public employees across the different departments and sections of the ministries. The data is pulled from the Depkatalog API (https://depkatalog.no).",
      title: 'OECD Employees'
    },
    component: () => import('@/views/OECDEmployees.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const { setTitle, setDescription } = useMeta()

  const title = to.matched.slice().reverse().find(path => {
    return path.meta.title
  })?.meta.title

  const description = to.matched.slice().reverse().find(path => {
    return path.meta.description
  })?.meta.description

  setTitle(title)
  setDescription(description)
})

export default router
