<template>
  <div
    class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-min-h-full tw-w-full tw-py-24"
  >
    <div class="tw-container tw-mx-auto">
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
        <router-link
          :key="index"
          :to="{ name: example.route }"
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          tag="div"
          v-for="(example, index) in examples"
        >
          <img
            :src="getPreview(example.title)"
            v-if="getPreview(example.title)"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">{{ example.title }}</h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              {{ example.description }}
            </p>
          </div>
        </router-link>
        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://ffact.shinyapps.io/_innbyggerundersokelsen"
        >
          <img
            :src="getPreview('Velg emne fra Innbyggerundersøkelsen')"
            v-if="getPreview('Velg emne fra Innbyggerundersøkelsen')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Velg emne fra Innbyggerundersøkelsen
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose"></p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://ffact.shinyapps.io/_statsregnskapet2/"
        >
          <img
            :src="
              getPreview(
                'Artskonto med siste tilgjengelige tall fra November 2016'
              )
            "
            v-if="
              getPreview(
                'Artskonto med siste tilgjengelige tall fra November 2016'
              )
            "
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Artskonto med siste tilgjengelige tall fra November 2016
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose"></p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://ffact.shinyapps.io/_statsregnskapet/"
        >
          <img
            :src="getPreview('Velg kapittelenhet i Statsregnskapet')"
            v-if="getPreview('Velg kapittelenhet i Statsregnskapet')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Velg kapittelenhet i Statsregnskapet
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose"></p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://beta.apenhet.com/visualization/eupan"
        >
          <img
            :src="getPreview('General Secretary')"
            v-if="getPreview('General Secretary')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">General Secretary, 2018, EUPAN</h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is a data visualization of
              <a href="http://eupan.org/">EUPAN</a>'s
              <i>General Secretary</i> survey of 2018.
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://beta.apenhet.com/visualization/d934caf0-29f6-11e9-9b19-f57e175efa96"
        >
          <img
            :src="
              getPreview(
                'Planning Steering and Co-ordinating Implementation of the Sustainable Development Goals'
              )
            "
            :v-if="
              getPreview(
                'Planning Steering and Co-ordinating Implementation of the Sustainable Development Goals'
              )
            "
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Planning Steering and Co-ordinating Implementation of the
              Sustainable Development Goals, 2019, OECD
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is a data visualization of
              <a href="https://oecd.org/">OECD</a>'s
              <i
                >Planning Steering and Co-ordinating Implementation of the
                Sustainable Development Goals</i
              >
              survey of 2019.
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://beta.apenhet.com/visualization/b2f8a120-f19a-11e8-9a82-4df921efb3ed"
        >
          <img
            :src="getPreview('Accrual Accounting and Budgeting')"
            :v-if="getPreview('Accrual Accounting and Budgeting')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Accrual Accounting and Budgeting, 2016, OECD
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is a data visualization of
              <a href="https://oecd.org/">OECD</a>'s
              <i>Accrual Accounting and Budgeting</i> survey of 2016.
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://beta.apenhet.com/visualization/9bd15180-f1de-11e8-a496-5178fe5a7c39"
        >
          <img
            :src="getPreview('Citizen\'s Charter')"
            :v-if="getPreview('Citizen\'s Charter')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">Citizen's Charter, 2018, OECD</h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is a data visualization of
              <a href="https://oecd.org/">OECD</a>'s
              <i>Citizen's Charter</i> survey of 2018.
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://beta.apenhet.com/visualization/96edff40-2a00-11e9-8355-e3fadc17391c"
        >
          <img
            :src="getPreview('Public Procurement')"
            :v-if="getPreview('Public Procurement')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">Public Procurement, 2019, OECD</h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is a data visualization of
              <a href="https://oecd.org/">OECD</a>'s
              <i>Public Procurement</i> survey of 2019.
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://sigma.apenhet.com/service-delivery-2019"
        >
          <img
            :src="getPreview('Public Service Delivery')"
            :v-if="getPreview('Public Service Delivery')"
          />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Public Service Delivery, 2019, SIGMA
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is an online data presentation of the
              <i>2019 Serbia Monitoring Report</i> completed by
              <a href="http://sigmaweb.org/">SIGMA</a>. The selected area is
              "Public Service Delivery".
            </p>
          </div>
        </a>

        <a
          class="tw-bg-white tw-rounded tw-shadow-md tw-flex tw-flex-col tw-m-4 tw-cursor-pointer hover:tw-shadow-lg tw-max-w-sm tw-overflow-hidden"
          href="https://sigma.apenhet.com/public-service-and-hrm-2019"
        >
          <img :src="getPreview('PSHRM')" :v-if="getPreview('PSHRM')" />
          <div class="tw-p-8">
            <h2 class="tw-text-gray-darkest">
              Public Service and Human Resource Management, 2019, SIGMA
            </h2>
            <p class="tw-mt-4 tw-text-gray-dark tw-leading-loose">
              This is an online data presentation of the
              <i>2019 Serbia Monitoring Report</i> completed by
              <a href="http://sigmaweb.org/">SIGMA</a>. The selected area is
              "Public Service and Human Resource Management".
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

export const examples = [
  {
    title: "DFØ Employees",
    route: "DFOEmployees",
    description:
      "Sunburst graph displaying all DFØ public employees across the different sections."
  },
  {
    title: "OECD Employees",
    route: "OECDEmployees",
    description:
      "Sunburst graph displaying all OECD employees across the different departments and sections of the organization. The data is pulled from the OECD phone directory."
  },
  {
    title: "Norwegian Employees",
    route: "NorwegianEmployees",
    description:
      "Sunburst graph displaying all Norwegian public employees across the different departments and sections of the ministries. The data is pulled from the Depkatalog API (https://depkatalog.no)."
  }
];

/**
 * Current respondent
 */
export const respondent = ref("FR");

/**
 * Comparisons.
 */
export const comparisons = ref(["IT"]);

/**
 * EUPAN link.
 */
export const eupanLink = computed(() => {
  return `https://beta.apenhet.com/eupan?=respondent=${
    respondent.value
  }&comparisons=${comparisons.value.join(",")}`;
});

/**
 *
 */
export const getPreview = (name: string) => {
  try {
    return require(`@/assets/previews/${name}.png`);
  } catch (error) {
    return null;
  }
};
</script>
